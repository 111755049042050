import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

const TinyRecent = () => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(
        sort: { fields: context___date, order: DESC }
        filter: { context: { date: { ne: null } } }
        limit: 3
      ) {
        edges {
          node {
            id
            path
            context {
              id
              name
              parent
              title
              type
              depth
              date
              excerpt
            }
          }
        }
      }
    }
  `)
  const posts = data.allSitePage.edges.map(article => {
    return (
      <li key={article.node.path}>
        <Link to={article.node.path}>{article.node.context.title}</Link>
      </li>
    )
  })
  return (
    <div>
      <h3>Recent Blog Posts:</h3>
      <ul>{posts}</ul>
    </div>
  )
}

export default TinyRecent
