import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import TinyRecent from "./tinyrecent"

const FooterBar = ({ siteTitle }) => (
  <>
    <div className="columns">
      <div className="column">
        <Link to="/">Home</Link>
      </div>
      <div className="column">
        <ul>
          <StaticQuery
            query={graphql`
              {
                allSitePage(
                  filter: { path: {}, context: { depth: { eq: 1 } } }
                  sort: { order: ASC, fields: path }
                ) {
                  edges {
                    node {
                      context {
                        id
                        depth
                        parent
                        title
                      }
                      path
                    }
                  }
                }
              }
            `}
            render={data => {
              return data.allSitePage.edges.map(article => {
                return (
                  <li key={article.node.path}>
                    <Link to={article.node.path}>
                      {article.node.context.title}
                    </Link>
                  </li>
                )
              })
            }}
          />
        </ul>
      </div>
      <div className="column">
        <TinyRecent />
      </div>
    </div>
  </>
)

FooterBar.propTypes = {
  siteTitle: PropTypes.string,
}

FooterBar.defaultProps = {
  siteTitle: ``,
}

export default FooterBar
