import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../content/assets/wirehead-fm.svg"
import MenuBar from "gatsby-theme-wirehead-bulma/src/menubar"

const Header = ({ siteTitle }) => (
  <div className="headerbar">
    <div className="container center has-text-centered">
      <Link to="/">
        <img src={logo} alt="logo" className="is-text-centered" />
      </Link>
    </div>
    <MenuBar siteTitle={siteTitle} endLinks={
      <Link to="/mail" className="navbar-item">
          &#9993; Mail
        </Link>
    }/>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
